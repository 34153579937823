export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "PROD",
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://fwd9arakqj.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_C6IfjDCIa",
    APP_CLIENT_ID: "t4f5v11kqtgq8l4kv86vhkbqq",
    DOMAIN: "https://autoauth-support.auth.us-west-2.amazoncognito.com",
    IDENTITY_POOL_ID: "us-west-2:9f19f768-bd1a-4cff-9a1a-a8d0bc02e513"
  },
  TRUSTX_PROCESS_DEFINITION_NAME: "eval",
  TRUSTX_PROCESS_DEFINITION_ID: "JDWTDK25FTSLEOIM7GQ2RB2LKY",
  TRUSTX_PROCESS_DEFINITION_VERSION: "4",
};
