import React, { createContext, useContext, useState, useEffect } from 'react';
import { getVinInfo } from '../libs/db-lib';


// Define the context type
type VinDetails = {
  Make: string;
  Model: string;
  Year: string;
  Trim: string;
};

type VinCacheContextType = {
  getVinInfo: (vin: string) => Promise<VinDetails | undefined>;
};


// create the screen context
export const VinCacheContext = createContext<VinCacheContextType>({
  getVinInfo: (vin: string) => Promise.resolve(undefined),
});

export function useVinCache() {
  return useContext(VinCacheContext);
}

type VinCacheProviderType = {
  children: JSX.Element;
};

const handleGetVinInfo = async (vin: string) => {
  const vinInfo = await getVinInfo([vin]);
  let Make = 'The make could not be determined';
  let Model = 'The model could not be determined';
  let Year = 'The year could not be determined';
  let Trim = 'The trim could not be determined';
  if (Array.isArray(vinInfo) && vinInfo[0]) {
    Make = vinInfo[0].Make;
    Model = vinInfo[0].Model;
    Year = vinInfo[0].Year;
    Trim = vinInfo[0].Trim || 'The trim could not be determined';
  } else {
    console.log(`Fetching from vpic.nhtsa.dot.gov: ${vin}`)
    const response = await fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    let data = await response.json();
    let results = data['Results'][0];
    Make = results.Make;
    Model = results.Model;
    Year = results.ModelYear;
    Trim = results.Trim;
  }
  const newVehicleObj = {
    VIN: vin,
    Make: Make,
    Model: Model,
    Year: Year,
    Trim: Trim,
  };
  return newVehicleObj
}

export function VinCacheProvider({ children }: VinCacheProviderType) {

  const [vinCache, setVinCache] = useState<{ [vin: string]: VinDetails }>({});

  const getVinInfo = async (vin: string) => {
    if (vinCache[vin]) {
      return vinCache[vin];
    } else {
      const newVinInfo = await handleGetVinInfo(vin);
      vinCache[vin] = newVinInfo;
      setVinCache(vinCache);
      return newVinInfo;
    }
  }

  const provider = {
    getVinInfo,
  };

  return (
    <VinCacheContext.Provider value={provider}>
      {children}
    </VinCacheContext.Provider>
  );
}
